@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  scroll-behavior: smooth;
  scroll-snap-type: y proximity;
  scrollbar-gutter: initial;
  overflow-X: hidden;
}

.app {
  background-color: black;
  overflow: hidden;
}

::selection {
  background: #dfd30b;
  color: black;
}

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.mainTing {
  z-index: -1;
}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

.navbar {
  display: flex;
}

.navbar-wrapper {
  flex: 1;
  float: right;
  display: flex;
  margin-right: 3%;
  margin-left: 65%;
  height: fit-content;
}

.link-wrapper {
  flex: 1;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: white;
  text-align: right;
  font-size: 1.3em;
  font-weight: 400;
}

.link-wrapper a {
  text-decoration: none;
  color: white;
}

.link-wrapper a:hover {
  opacity: 80%;
}

.link-wrapper a::after {
  color: white;
}

.nav-sc {
  height: 4em;
}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

.mobile-nav-wrapper {

  visibility: hidden;
}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */



.scorpionSect {
  min-height: 100vh;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: white;
  background-color: black;
  background-image: url("./components/images/LABZ_LARGE.png");
  background-repeat: no-repeat;
  background-size: 95%;
  background-position:50%;
  padding: 3%;
  padding-top: 2%;
  padding-right: 0;
  overflow: visible;
  display: block;
  margin-bottom: 5em;

}

.scorpion-main {
  display: block;
}

.scorpion-main-title-wrapper {
  margin-top: -3em;
  
}

.scorpion-main-title {
  line-height: 0.8;
  font-size: 8em;
  font-weight: 300;
  transition: all .4s linear;
}

.scorpion-main-title::selection {
  background: #dfd30b;
  color: black;
}

.scorpion-main-subtitle-wrapper {
  text-align: center;
  margin-top: 20em;

}

.scorpion-main-subtitle::selection {
  background: #dfd30b;
  color: black;
}

.scorpion-main-subtitle {
  line-height: 1;
  font-size: 6rem;
  font-weight: 300;
}

.subtitle-bold {
  -webkit-text-fill-color: white;
  font-weight: 900;
  text-shadow: 3px 3px rgba(255, 255, 255, 0.2);

}

.scorpion-main-title-wrapper-mobile {
  display: none;
}

.scorpion-newsletter {

  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scorpion-newsletter-form-wrapper {
  width: 30em;
  background: rgba( 73, 73, 73, 0.3 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 14.5px );
  -webkit-backdrop-filter: blur( 14.5px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  padding: 1em;
  padding-top: 0;
}

.button-container {
  margin-top: 1em;
  position: relative;
  height: 4em;
}

.submit-wrapper {
  align-items: center;
  margin: 0;
  top: 50%;
  left: 50%;
  position: absolute;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.submit-button-scorp {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 200px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 800;
  font-family: sans-serif;
  text-decoration: none;
  color: white;
  border: 1px solid white;
  background: none;
  border-radius: 10px;
  text-align: center;
  position: relative;
  transition: all .35s;

}

.submit-button-scorp span {
  position: relative;
  z-index: 2;
}

.submit-button-scorp:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  color: white;
  background: #202cf4;
  border-radius: 10px;
  transition: all .35s;
}

.submit-button-scorp:hover {
  
  border: 1px solid #202cf4; 
  color: white;
}

.submit-button-scorp:hover::after {
  width: 100%;
}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

.serviceSect {
  min-height: fit-content;
  margin-right: 5%;
  margin-left: 5%;

}

.serviceSect-main {
  display: flex;
}

.serviceSect-main-left {
  flex: 1;
  background-image: url("./components/images/dot_art.svg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: visible;
  overflow-x: visible;
  display: block;
  
}

.what-we-do {
  justify-content: center;
  margin-top: 5em;  
}

.services-title {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: white;
  font-weight: 900;
  font-size: 10.5em;
  line-height: 0.8;
  margin-bottom: 10%;
  text-shadow: 0 0 1em #202cf4;
}

.services-title::selection {
  background: #dfd30b;
  color: black;
}

.serviceSect-main-right {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.service-card {
  background: rgba( 73, 73, 73, 0.1 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 14.5px );
  -webkit-backdrop-filter: blur( 14.5px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  width: 80%;
  height: fit-content;
  padding: 5%;
  margin-left: 10%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.service-card-title {
  color: white;
  font-size: 3em;
  font-weight: 600;
}

.service-card-subtitle {
  color: rgba( 73, 73, 73, 1.0 );
  font-size: 2em;
  line-height: 1;

}

.service-card-description {
  margin-top: 5%;
  color: lightgrey;
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1.4;
}

.serviceSect-lower {
  height: 200px;
}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

.scorpion-button {
  transform: translate(45%, 100%);
}


.buttonName {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: block;
  width: 200px;
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  font-weight: 800;
  font-family: sans-serif;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.394);
  border: 1px solid rgba(255, 255, 255, 0.394);
  border-radius: 10px;
  text-align: center;
  position: relative;
  transition: all .35s;

}

.buttonName span {
  position: relative;
  z-index: 2;
}

.buttonName:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: rgba(32, 44, 244, 0.7);
  border-radius: 10px;
  transition: all .35s;
}

.buttonName:hover {
  color: #fff;
  border: 1px solid rgba(32, 44, 244, 0.7);
  
}

.buttonName:hover::after {
  width: 100%;
}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

.logoSect {

  position: relative;
  height: 100vh;
  overflow: hidden;
  background-image: url("./components/images/waves_background.png");

}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

.about-sect {
  background: 
    radial-gradient(
      farthest-side at bottom left,
      rgba(32, 44, 244, 0.5), 
      transparent
    ),
    radial-gradient(
      farthest-corner at bottom right,
      rgba(35, 44, 240, 0.9), 
      transparent 200px
    );
  min-height: fit-content;
  padding: 3%;
  display: block;
}

.about-sect-top {
  display: flex;
}

.about-sect-title-wrapper {
  flex: 1;
}

.about-main-right-wrapper {
  flex: 5;
  display: block;
}

.about-sect-title {
  writing-mode: vertical-rl;
  text-orientation: upright; 
  -webkit-text-fill-color: white;
  font-size: 10em;
  line-height: 0.6;
  font-weight: 900;
  letter-spacing: -0.5em;
}

.about-sect-main {
  background: rgba( 73, 73, 73, 0.1 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 14.5px );
  -webkit-backdrop-filter: blur( 14.5px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  height: fit-content;
  padding: 3%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: rgb(170, 170, 170);
  font-size: 1.5em;
  transition: all 0.5s;
}

.about-sect-main:hover {
  background-color: rgba( 255, 255, 255, 0.05 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.2);
  backdrop-filter: blur( 20px );
  -webkit-backdrop-filter: blur( 20px );
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.about-story-header {
  font-size: 3em;
  margin-bottom: 2em;
  font-weight: 700;
  color: white;
}

.about-par {
  font-weight: 600;
}

.about-gallery {
  margin-top: 3em;

  
}

.about-gallery-pic-wrapper {
  width: 100%;
}

.about-gallery-design {
  width: 100%;
}



.about-numbers {
  margin-top: 3em;
  display: flex;
}

.about-numbers-wrapper {
  flex: 1;
  color: white;
  background: rgba( 73, 73, 73, 0.1 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 14.5px );
  -webkit-backdrop-filter: blur( 14.5px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  padding: 1em;
  user-select: none;
}

.about-numbers-wrapper:hover {
  background-color: rgba( 255, 255, 255, 0.05 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.2);
  backdrop-filter: blur( 20px );
  -webkit-backdrop-filter: blur( 20px );
  transition: all .5s;
}

.about-numbers-title {
  font-size: 8em;
  font-weight: 700;
  color: white;
  text-shadow: 0 0 3em #202cf4;
}

.about-numbers-subtitle {
  font-size: 2em;
  font-weight: 700;
  line-height: 1;
  color: rgba(255, 255, 255, 0.403);
  text-transform: uppercase;
}

#one-5 {
  text-align: left;
}

#inf {
  text-align: center;
  margin-left: 1em;
  margin-right: 1em;
}

#five {
  text-align: right;
}

.about-gallery-mobile {
  visibility: hidden;
  display: none;
}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

.process-main {
  min-height: 100vh;
  display: block;
   background: 
  radial-gradient(
    farthest-side at top left,
    rgba(32, 44, 244, 0.5), 
    transparent
  ),
  radial-gradient(
    farthest-corner at top right,
    rgba(35, 44, 240, 0.9), 
    transparent 200px
  );
}

.process-title-wrapper {
  text-align: center;
}

.process-title {
  font-size: 8rem;
  overflow: hidden;
  z-index: 1;
  color: #202bf47d;
  font-weight: 800;
}

.process-title-h1 {
  margin-top: 0.3em;
  position: absolute;
  font-family: Arial system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 800;
  font-size: 4em;
}

.processSect {
  display: flex;

}

.process-card {
  flex: 1;
  padding: 1%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  transition: background-color 1s ease;
}

.process-card:hover {
  background-color: rgba( 255, 255, 255, 0.05 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.2);
  backdrop-filter: blur( 20px );
  -webkit-backdrop-filter: blur( 20px );
}

.process-card-id {
  color: rgb(26, 35, 194);
  font-size: 5em;
  font-weight: 700;
}

.process-card-title {
  color: white;
  font-size: 2.7em;
  font-weight: 300;
  line-height: 3;
}

.process-card-description {
  color: lightgrey;
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1.3;
}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

.clients-sect {
  min-height: 40vh;
  padding: 3%;
}

.clients-sect-logos {
  margin-top: 10em;
  display: flex;
  height: fit-content;
  flex-direction: row;
  row-gap: 10em;
  width: 100%;
}

.client-card {
  flex: 1;
  display: block;
  text-align: center;
}

.client-card-logo {
  width: 100%;
  left: 0;
  top: 0;
}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

.contact-sect {
  min-height: 80vh;
  padding: 3%;
}

.contact-container {
  background: rgba( 73, 73, 73, 0.1 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 14.5px );
  -webkit-backdrop-filter: blur( 14.5px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  height: 100%;
  padding: 2%;
}

.contact-form-title-cont {
  width: 100%;
  text-align: center;
}

.contact-form-title {
  color: white;
  font-size: 7em;
  font-weight: 900;
}

.contact-form-input-cont {
  margin-top: 1em;
  
}

.contact-form-email {
  background: transparent;
  border: none;
  border-bottom: 2px solid white;
  width: 100%;
  caret-color: white;
  padding: 0.1em;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  font-size: 1.5em;
  color: white;
  margin-bottom: 1.5em;

}

.contact-form-email::placeholder {
  opacity: 50%;
}

.contact-form-email:focus, input:focus {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.5em;
  color: white;
}

input select .contact-form-email {
  color: white;
}

.contact-form-email:focus, input:focus {
  outline: none;
}

.contact-form-email-inquiry {
  width: 100%;
  border: none;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: transparent;
  color: white;
  border-bottom: 2px solid white;
  font-size: 1.5em;
  padding: 0.3em;
}

.contact-form-email-inquiry::placeholder {
  opacity: 50%;
}

.contact-form-email-inquiry:focus, input:focus {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.5em;
  color: white;
  outline: none;
}

.submit-status-container {
  text-align: center;
}
.submit-good {
  color: red;
  font-size: 2em;
}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

.footer-section {
  min-height: 60vh;
  display: block;
  background-image: url("./components/images/to.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  padding: 3%;
  padding-bottom: 0;
}

.footer-1 {
  display: flex;
}

.footer-1-left {
  flex: 3;
  top: 0;
}

.footer-1-right {
  flex: 1;
}

.footer-1-far-right {
  flex: 1;
}

.footer-2 {
  margin-top: 1%;
  width: 10%;
}

.footer-2-socials {
  display: flex;
}

.footer-2-socials-icon {
  flex: 1;
}

.footer-icon {
  width: 2em;
}

.footer-list {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.5em;
  font-weight: 400;
  color: white;
  list-style: none;
  text-decoration: none;
}

.footer-list-header {
  color:#4b5362;
  font-weight: 700;
}

.footer-list-item {
  font-weight: 900;
  color: white;
  line-height: 1.5;
  
}

.footer-list-item a {
  color: white;
  text-decoration: none;
}

.footer-list-item a:hover {
  opacity: 50%;
}

.footer-right-2 {
  flex: 1;
}

.footer-form {
  font-family: Arial, Helvetica, sans-serif;
  width: fit-content;
  
}

.newsletter-header {
  color:white;
  font-weight: 100;
  font-size: 2.5em;
}

.newsletter-header-slabz {
  color: white;
  font-weight: 1000;
  font-size: 2.5em;
  line-height: 1;
}

.newsletter-form-email-wrapper {
  margin-top: 1em;
  height: 2em;
  
}

.newsletter-form-email {
  background: transparent;
  border: none;
  border-bottom: 2px solid white;
  width: 100%;
  height: 100%;
  caret-color: white;
  padding: 0.1em;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  font-size: 1.5em;
  color: white;
  text-align: center;

}

.newsletter-form-email::placeholder {
  color: white;
  opacity: 50%;

}

input {
  outline: none;
}

.newsletter-form-email:focus, input:focus {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.5em;
  color: white;
  
}

input select .newsletter-form-email {
  color: white;
}

.newsletter-form-email:focus, input:focus {
  outline: none;
}

.button-container {
  margin-top: 1em;
  position: relative;
  height: 4em;
}

.submit-wrapper {
  align-items: center;
  margin: 0;
  top: 50%;
  left: 50%;
  position: absolute;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.submit-button {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: block;
  width: 200px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 800;
  font-family: sans-serif;
  text-decoration: none;
  color: #202cf4;
  border: 1px solid white;
  border-radius: 10px;
  text-align: center;
  position: relative;
  transition: all .35s;

}

.submit-button span {
  position: relative;
  z-index: 2;
}

.submit-button:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #202cf4;
  border-radius: 10px;
  transition: all .35s;
}

.submit-button:hover {
  color: white;
  border: 1px solid #202cf4;
  border-radius: 10px;
  
}

.submit-button:hover::after {
  width: 100%;
}

.contact-ul {
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.5;
}

.footer-3 {
  display: flex;
  width: 100%;

}

.footer-3-credits {
  flex: 1;
  color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1em;
  font-weight: 200;
}

.footer-3-credits #left {
  text-align: left;
}

.footer-3-credits #middle {
  text-align: center;
}

.footer-3-credits #right {
  text-align: right;
}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ------ MOBILE-OPTIMIZED DESIGN SECTION ------------------------------------------------------------------------------------------------------------------------------------------------------------ */

@media screen and (max-width: 450px) {

  html {

  }


  /*-----------------------MOBILE NAVBAR------------------------*/

  .mobile-nav-wrapper {
    visibility: visible;
  }



  /*-----------------------SCORPION SECTION------------------------*/
  .scorpionSect {
    padding: 1em;
    background-image: url('./components/images/labz_mobile.png');
    background-size: cover;
  }


  .navbar {
    display: none;
    
  }

  .scorpion-main-title-wrapper {
    display: none;
  }

  .scorpion-main-subtitle-wrapper {
    display: none;
  }

  
  .scorpion-main-title-wrapper-mobile {
    display: contents;
  }
  
  .subtitle-bold-yellow {

    color: #dfd30b;
    font-size: 12rem;
    line-height: 1;
  }

  .scorpion-main-title-mobile {
    font-size: 8.5em;
    font-weight: 800;
    line-height: 0.75;
  }

  .scorpion-main-subtitle {
    font-size: 2.5em;
  }

  .mobile-subtitle-wrapper {
    display: flex;
  }

  .mobile-subtitle {
    flex: 1;
    font-size: 1.3em;
    color: whitesmoke;
  }

  .scorpion-newsletter {
    text-align: center;
    justify-content: left;
    align-items: center;
  }

  .scorpion-newsletter-form-wrapper {
    width: 100%;
  }

  .scorpion-newsletter-input {
    text-align: center;
  }

  .newsletter-form-email {
    text-align: center;
    padding-bottom: 0.5em;
  }

  /*-----------------------BURGER MENU------------------------*/

  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 2em;
    top: 2em;
  }

  .bm-burger-bars {
    background: white;
  }

  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #202bf4e9;
    padding: 2.5em 1.5em 0;
    font-size: 2em;
    width: 100%;
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 900;
  }

  .bm-menu a {
    text-decoration: none;
    color: white;
  }

  .bm-menu a::after {
    text-decoration: none;
    color: white;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #202cf4;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  /*-----------------------SERVICES SECTION------------------------*/

  .serviceSect {
    margin: 0;
    margin-top: 2em;
    padding: 1em;

  }

  .serviceSect-main {
    display: block;
  }

  .services-title {
    font-size: 8em;
  }

  .serviceSect-main-left {
    padding: 3%;
    margin-bottom: 3em;
    background-image: none;
    text-align: center;
  }

  .serviceSect-main-right {
    display: block;
    margin-right: 10%;
  }

  .service-card {
    padding: 5%;
    margin-left: 0;
    margin-top: 15px;
    width: 100%;
  }

  .serviceSect-lower {
    margin-top: 2em;
  }

  /*-----------------------LOGO SECTION----------------------------*/
  
  .scorpion-button {
    transform: translate(25%, 0);
  }
  
  /*-----------------------LOGO SECTION----------------------------*/

  .logoSect {
    height: 80vh;
  }

  .logo-pict {
    width: 50%;
  }

  /*-----------------------ABOUT SECTION---------------------------*/

  .about-sect.top {
    display: block;
  }

  .about-sect-title-wrapper {
    display: none;
  }

  .about-sect-title {
    display: none;
  }


  .about-story-header {
  
    margin-bottom: 0.5em;
  }

  .about-numbers {
    display: block;
    text-align: center;
    margin-top: 0.5em;
  }

  .about-numbers-wrapper {
    margin-top: 15px;
    border: 1px solid white;
    padding: 5%;
    text-align: center;
    flex: 1;
  }

  .about-numbers-title {
    font-size: 2em;
  }

  #one-5 {
    text-align: center;
  }
  
  #inf {
    margin-left: 0;
    margin-right: 0;
  }

  #five {
    text-align: center;
  }

  .about-gallery {
    visibility: hidden;
    display: none;
  }

  .about-gallery-mobile {
    visibility: visible;
    display: contents;

  }

  .about-gallery-design-mobile {
    max-width: 100%;
  }

  /*-----------------------PROCESS SECTION-------------------------*/

  .process-main {
    padding: 1em;
  }
  
  .process-title {
    font-size: 3.5em;
  }

  .processSect {
    display: block;
  }

  .process-card {
    margin-bottom: 1em;
  }

  .process-card-id {
    font-size: 2em;
  }

  .process-card-title {
    font-size: 3.5em;
  }

  #mobile-button {
    margin-top: 4em;
  }

  /*-----------------------CLIENTS SECTION-------------------------*/
  
  .clients-sect-logos {
    display: block;
  }

  .client-card-logo {
    width: 50%;
  }

  /*-----------------------CONTACT SECTION-------------------------*/
  .contact-sect {
    display: none;
  }
  
  .contact-form-title {
    font-size: 4em;
  }

  .contact-form-email {
    border: none;
  }

  /*-----------------------FOOTER SECTION-------------------------*/

  .footer-1 {
    display: block;

  }

  .footer-form {
    display: none;
  }

  .newsletter-header {
    font-size: 1em;
  }

  .footer-1-far-right {
    margin-top: 1em;
  }

  .footer-2 {
    margin-top: 2em;
    width: 50%;
  }

  .footer-2-socials {
    margin-left: 20%;
  }
}
